// extracted by mini-css-extract-plugin
export var base = "MarketingCaseStudyRoundupPage-module--base--edO2Y";
export var rightAlignedContainer = "MarketingCaseStudyRoundupPage-module--rightAlignedContainer--7Lhpo";
export var rightAlignedContainerSmall = "MarketingCaseStudyRoundupPage-module--rightAlignedContainerSmall--2aiM2";
export var leftAlignedContainer = "MarketingCaseStudyRoundupPage-module--leftAlignedContainer--2I86w";
export var blogContainer = "MarketingCaseStudyRoundupPage-module--blogContainer--Pw27W MarketingCaseStudyRoundupPage-module--base--edO2Y";
export var container = "MarketingCaseStudyRoundupPage-module--container--3rQLT MarketingCaseStudyRoundupPage-module--base--edO2Y";
export var largeContainer = "MarketingCaseStudyRoundupPage-module--largeContainer--3Pq7z MarketingCaseStudyRoundupPage-module--base--edO2Y";
export var mobileSmallPadding = "MarketingCaseStudyRoundupPage-module--mobileSmallPadding--1zOx8";
export var fullWidthMobile = "MarketingCaseStudyRoundupPage-module--fullWidthMobile--3aj0h";
export var gridWithSidebar = "MarketingCaseStudyRoundupPage-module--gridWithSidebar--199kM";
export var noMaxWidth = "MarketingCaseStudyRoundupPage-module--noMaxWidth--3m5_q";
export var hero = "MarketingCaseStudyRoundupPage-module--hero--2aw4x";
export var copyLockupList = "MarketingCaseStudyRoundupPage-module--copyLockupList--35yGJ";
export var featuredContents = "MarketingCaseStudyRoundupPage-module--featuredContents--2-gYo";
export var sectionHeader = "MarketingCaseStudyRoundupPage-module--sectionHeader--2WJh0";
export var pitch = "MarketingCaseStudyRoundupPage-module--pitch--336H4";
export var videoPlayer = "MarketingCaseStudyRoundupPage-module--videoPlayer--eZSSw";